// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};


export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let totalSupply1 = await store
        .getState()
        .blockchain.smartContract.methods.minted(1)
        .call();

      let totalSupply2 = await store
        .getState()
        .blockchain.smartContract.methods.minted(2)
        .call();

      let totalSupply3 = await store
        .getState()
        .blockchain.smartContract.methods.minted(3)
        .call();

      let totalSupply = await [];
      totalSupply[0] = totalSupply1;
      totalSupply[1] = totalSupply2;
      totalSupply[2] = totalSupply3;

      dispatch(
        fetchDataSuccess({
          totalSupply,
          // cost,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};