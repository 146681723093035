import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";


const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 20px;
  border-radius: 50px;
  border: none;
  background-color: var(--primary);
  padding: 20px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton2 = styled.a`
  padding: 30px;
  border-radius: 50px;
  border: none;
  background-color: var(--new-text);
  padding: 30px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--primary-text);
  width: 400px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton3 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-family: 'Press Start 2P';
  font-weight: bold;
  color: var(--new-text);
  width: 200px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledButton4 = styled.a`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--accent);
  padding: 10px;
  font-family: 'Press Start 2P';
  color: var(--new-text);
  width: 400px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--primary-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border-radius: 0%;
  min-width: 350px;
  max-width: 1000px;
  min-height: 350px;
  max-height: 1000px;

  width: 100%;

  @media (min-width: 900px) {
    width: 100%;
  }
  @media (min-width: 1000px) {
    width: 100%;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: var(--primary);
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You are minting One pack.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },

    NFT_NAME_MINI: "",
    SYMBOL_MINI: "",
    MAX_SUPPLY_MINI: 1,
    WEI_COST_MINI: 0,
    DISPLAY_COST_MINI: 0,
    GAS_LIMIT_MINI: 0,

    NFT_NAME_STANDARD: "",
    SYMBOL_STANDARD: "",
    MAX_SUPPLY_STANDARD: 1,
    WEI_COST_STANDARD: 0,
    DISPLAY_COST_STANDARD: 0,
    GAS_LIMIT_STANDARD: 0,

    NFT_NAME_COLLECTOR: "",
    SYMBOL_COLLECTOR: "",
    MAX_SUPPLY_COLLECTOR: 1,
    WEI_COST_COLLECTOR: 0,
    DISPLAY_COST_COLLECTOR: 0,
    GAS_LIMIT_COLLECTOR: 0,

    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTsMini = () => {
    let cost = CONFIG.WEI_COST_MINI;
    let gasLimit = CONFIG.GAS_LIMIT_MINI;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME_MINI}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .packMintMini()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: String(800000000000000000000n),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `YAY! You minted a ${CONFIG.NFT_NAME_MINI}! You may open it for 3 cards!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsStandard = () => {
    let cost = CONFIG.WEI_COST_STANDARD;
    let gasLimit = CONFIG.GAS_LIMIT_STANDARD;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME_STANDARD}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .packMintStandard()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: String(3000000000000000000000n),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `YAY! You minted a ${CONFIG.NFT_NAME_STANDARD}! You may open it for 6 cards!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const claimNFTsCollector = () => {
    let cost = CONFIG.WEI_COST_COLLECTOR;
    let gasLimit = CONFIG.GAS_LIMIT_COLLECTOR;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME_COLLECTOR}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .packMintCollector()
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: String(15000000000000000000000n),
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `YAY! You minted a ${CONFIG.NFT_NAME_COLLECTOR}! You may open it for 9 cards!`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/.png" : null}
      >



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>
          <s.Container flex={1} jc={"center"} ai={"center"}>
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              SGB Craft Minter
            </s.MainTitle>

            <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#mini"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Get<br></br>Mini Pack
                </StyledButton4>

              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#standard"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Get<br></br>Standard Pack
                </StyledButton4>
              </s.Container>

              <s.Container style={{ padding: 10, }} flex={1} jc={"center"} ai={"center"}>
                <StyledButton4
                  href="#collector"
                  style={{
                    fontSize: 24,
                    lineHeight: 1.6,
                    textAlign: "center",
                    textDecoration: 'none',
                  }}
                >
                  Get<br></br>Collector Pack
                </StyledButton4>
              </s.Container>

            </ResponsiveWrapper>

            <StyledButton2
              href="https://sgbcraft.coinylabs.com"
              style={{
                fontSize: 30,
                textAlign: "center",
                textDecoration: 'none',
              }}
            >
              Open A Pack
            </StyledButton2>

          </s.Container>

        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>

          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 20,
              borderRadius: 0,
            }}
          >
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Mini<br></br>Pack
            </s.MainTitle>
            <StyledImg src={"/config/images/minipack.png"} />

            <s.SpacerSmall />

            <s.Container
              flex={1}
              jc={"top"}
              ai={"center"}
              style={{
                backgroundColor: "var(--new-text)",
                padding: 24,
                borderRadius: 24,
                border: "4px  var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
              id={"mini"}
            >

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                {data.totalSupply[0]} / {CONFIG.MAX_SUPPLY_MINI}
              </s.TextTitle>
              <s.ContractAddress
                style={{
                  textAlign: "center",
                  color: "var(--primary)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.ContractAddress>
              <span
                style={{
                  textAlign: "center",
                }}
              >

              </span>
              <s.SpacerXSmall />
              {Number(data.totalSupply[0]) >= CONFIG.MAX_SUPPLY_MINI ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Supply limit reached.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Ask CoinyLabs to increase {CONFIG.NFT_NAME_MINI} supply!
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    A {CONFIG.SYMBOL_MINI} costs {CONFIG.DISPLAY_COST_MINI}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>

                  <s.SpacerMedium />

                  <s.TextDescription
                    style={{ textAlign: "center", size: 8, color: "var(--primary)" }}
                  >
                    Each {CONFIG.SYMBOL_MINI} contains 3 cards. Some are animated.
                    <br></br><br></br>
                    One of them might be a Rare card!
                    <br></br><br></br>
                    You also receive a Vote Token.
                  </s.TextDescription>

                  <s.SpacerMedium />

                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT SONGBIRD
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTsMini();
                            getData();
                          }}
                        >
                          {claimingNft ? "MINTING..." : "MINT NOW"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerSmall />

              <StyledButton3
                href="https://sgbcraft.coinylabs.com"
                style={{
                  fontSize: 24,
                  lineHeight: 1.6,
                  textAlign: "center",
                  textDecoration: 'none',
                }}
              >
                Open Packs
              </StyledButton3>

              <s.SpacerSmall />

              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME} Mainnet).
                <br></br><br></br>
                Your purchase is final and not refundable.
                <br></br><br></br>
                We use {CONFIG.GAS_LIMIT_MINI} gas limit to successfully mint your NFT.
              </s.TextDescription>

            </s.Container>

          </s.Container>

          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 20,
              borderRadius: 0,
            }}
          >
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Standard<br></br>Pack
            </s.MainTitle>
            <StyledImg src={"/config/images/standardpack.png"} />

            <s.SpacerSmall />

            <s.Container
              flex={1}
              jc={"top"}
              ai={"center"}
              style={{
                backgroundColor: "var(--new-text)",
                padding: 24,
                borderRadius: 24,
                border: "4px  var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
              id={"standard"}
            >

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                {data.totalSupply[1]} / {CONFIG.MAX_SUPPLY_STANDARD}
              </s.TextTitle>
              <s.ContractAddress
                style={{
                  textAlign: "center",
                  color: "var(--primary)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.ContractAddress>
              <span
                style={{
                  textAlign: "center",
                }}
              >

              </span>
              <s.SpacerXSmall />
              {Number(data.totalSupply[1]) >= CONFIG.MAX_SUPPLY_STANDARD ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Supply limit reached.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Ask CoinyLabs to increase {CONFIG.NFT_NAME_MINI} supply!
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    A {CONFIG.SYMBOL_STANDARD} costs {CONFIG.DISPLAY_COST_STANDARD}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>
                  
                  <s.SpacerMedium />

                  <s.TextDescription
                    style={{ textAlign: "center", size: 8, color: "var(--primary)" }}
                  >
                    Each {CONFIG.SYMBOL_STANDARD} contains 6 cards. Some are animated.
                    <br></br><br></br>
                    One of them is a Rare or even a Legendary card!
                    <br></br><br></br>
                    You also receive a Vote Token.
                  </s.TextDescription>

                  <s.SpacerMedium />

                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT SONGBIRD
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTsStandard();
                            getData();
                          }}
                        >
                          {claimingNft ? "MINTING..." : "MINT NOW"}
                        </StyledButton>

                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerSmall />

              <StyledButton3
                href="https://sgbcraft.coinylabs.com"
                style={{
                  fontSize: 24,
                  lineHeight: 1.6,
                  textAlign: "center",
                  textDecoration: 'none',
                }}
              >
                Open Packs
              </StyledButton3>

              <s.SpacerSmall />

              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME} Mainnet).
                <br></br><br></br>
                Your purchase is final and not refundable.
                <br></br><br></br>
                We use {CONFIG.GAS_LIMIT_MINI} gas limit to successfully mint your NFT.
              </s.TextDescription>

            </s.Container>

          </s.Container>



          <s.Container
            flex={1}
            jc={"center"}
            ai={"center"}
            style={{
              padding: 20,
              borderRadius: 0,
            }}
          >
            <s.MainTitle
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "var(--new-text)",
              }}
            >
              Collector<br></br>Pack
            </s.MainTitle>
            <StyledImg src={"/config/images/collectorpack.png"} />

            <s.SpacerSmall />

            <s.Container
              flex={1}
              jc={"top"}
              ai={"center"}
              style={{
                backgroundColor: "var(--new-text)",
                padding: 24,
                borderRadius: 24,
                border: "4px  var(--secondary)",
                boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
              }}
              id={"collector"}
            >

              <s.TextTitle
                style={{
                  textAlign: "center",
                  fontSize: 30,
                  fontWeight: "bold",
                  color: "var(--accent-text)",
                }}
              >
                {data.totalSupply[2]} / {CONFIG.MAX_SUPPLY_COLLECTOR}
              </s.TextTitle>
              <s.ContractAddress
                style={{
                  textAlign: "center",
                  color: "var(--primary)",
                }}
              >
                <StyledLink target={"_blank"} href={CONFIG.SCAN_LINK}>
                  {truncate(CONFIG.CONTRACT_ADDRESS, 15)}
                </StyledLink>
              </s.ContractAddress>
              <span
                style={{
                  textAlign: "center",
                }}
              >

              </span>
              <s.SpacerXSmall />
              {Number(data.totalSupply[2]) >= CONFIG.MAX_SUPPLY_COLLECTOR ? (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Supply limit reached.
                  </s.TextTitle>
                  <s.TextDescription
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    Ask CoinyLabs to increase {CONFIG.NFT_NAME_COLLECTOR} supply!
                  </s.TextDescription>
                  <s.SpacerXSmall />
                  <StyledLink target={"_blank"} href={CONFIG.MARKETPLACE_LINK}>
                    {CONFIG.MARKETPLACE}
                  </StyledLink>
                </>
              ) : (
                <>
                  <s.TextTitle
                    style={{ textAlign: "center", color: "var(--accent-text)" }}
                  >
                    A {CONFIG.SYMBOL_COLLECTOR} costs {CONFIG.DISPLAY_COST_COLLECTOR}{" "}
                    {CONFIG.NETWORK.SYMBOL}.
                  </s.TextTitle>

                  <s.SpacerMedium />

                  <s.TextDescription
                    style={{ textAlign: "center", size: 8, color: "var(--primary)" }}
                  >
                    Each {CONFIG.SYMBOL_COLLECTOR} contains 9 animated cards.
                    <br></br><br></br>
                    2 to 4 are Rare cards. One might even be a Legendary card!
                    <br></br><br></br>
                    You also receive a Vote Token.
                  </s.TextDescription>

                  <s.SpacerMedium />

                  {blockchain.account === "" ||
                    blockchain.smartContract === null ? (
                    <s.Container ai={"center"} jc={"center"}>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        Connect to the {CONFIG.NETWORK.NAME} network
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <StyledButton
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(connect());
                          getData();
                        }}
                      >
                        CONNECT SONGBIRD
                      </StyledButton>
                      {blockchain.errorMsg !== "" ? (
                        <>
                          <s.SpacerSmall />
                          <s.TextDescription
                            style={{
                              textAlign: "center",
                              color: "var(--accent-text)",
                            }}
                          >
                            {blockchain.errorMsg}
                          </s.TextDescription>
                        </>
                      ) : null}
                    </s.Container>
                  ) : (
                    <>
                      <s.TextDescription
                        style={{
                          textAlign: "center",
                          color: "var(--accent-text)",
                        }}
                      >
                        {feedback}
                      </s.TextDescription>
                      <s.SpacerSmall />
                      <s.Container ai={"center"} jc={"center"} fd={"row"}>
                        <StyledButton
                          disabled={claimingNft ? 1 : 0}
                          onClick={(e) => {
                            e.preventDefault();
                            claimNFTsCollector();
                            getData();
                          }}
                        >
                          {claimingNft ? "MINTING..." : "MINT NOW"}
                        </StyledButton>
                      </s.Container>
                    </>
                  )}
                </>
              )}
              <s.SpacerSmall />

              <StyledButton3
                href="https://sgbcraft.coinylabs.com"
                style={{
                  fontSize: 24,
                  lineHeight: 1.6,
                  textAlign: "center",
                  textDecoration: 'none',
                }}
              >
                Open Packs
              </StyledButton3>

              <s.SpacerSmall />

              <s.TextDescription
                style={{
                  textAlign: "center",
                  color: "var(--accent-text)",
                }}
              >
                Please make sure you are connected to the right network (
                {CONFIG.NETWORK.NAME} Mainnet).
                <br></br><br></br>
                Your purchase is final and not refundable.
                <br></br><br></br>
                We use {CONFIG.GAS_LIMIT_MINI} gas limit to successfully mint your NFT.
              </s.TextDescription>

            </s.Container>

          </s.Container>

        </ResponsiveWrapper>



        <ResponsiveWrapper flex={0} style={{ padding: 10 }} test>





        </ResponsiveWrapper>


        <s.SpacerMedium />

        <s.Container jc={"center"} ai={"center"} style={{ width: "70%" }}>

          <s.SpacerSmall />

        </s.Container>

      </s.Container>
    </s.Screen>
  );
}

export default App;